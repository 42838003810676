// console.log('Got to cropper javascript');

import * as Cropper from 'cropperjs'
import { createAlert } from '../scripts/createAlert';

// cropper implementation: https://github.com/fengyuanchen/cropperjs/blob/main/docs/examples/upload-cropped-image-to-server.html
// view -> app/views/devise/registrations/edit.html.erb

// Your code to run since DOM is loaded and ready
$(function () {
  const cropModal = document.getElementById('crop-modal');
  const modal = cropModal && new bootstrap.Modal(cropModal);
  var fileName;
  var cropper; //library

  var _this;
  var cropWidth, cropHeight, aspectRatio, previewId;

  $('.crop-cancel').on('click', function() {
    modal.hide();
    fileName = undefined;
    const dataTransfer = new DataTransfer()
    document.querySelector('.cropped-upload').files = dataTransfer.files
  })

  // This will update the preview image when a new image is selected
  $("body").on('change', '.cropped-upload', function(event) {
    _this = this

    // get the target dimensions for the image that was just selected
    console.log('Cropped upload requested', event.target.dataset)
    cropWidth = event.target.dataset.width
    cropHeight = event.target.dataset.height
    aspectRatio = event.target.dataset.aspectratio

    previewId = event.target.dataset.previewId;

    var reader; //file reader
    var done = function (url) {
      // show the image that they uploaded
      $("#avatar-cropper").attr("src", url);

      // open the crop modal
      modal.show();
    };

    reader = new FileReader();
    reader.onload = function (e) {
      done(reader.result);
    };

    // check filesize
    const fileSize = event.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;

    if (fileMb > 5) {
      createAlert('warning', 'Original file size cannot be bigger than 5mb. Please upload a smaller file.')
      modal.hide();

      // zero out the uploaded files on the fileInput
      let dataTransfer = new DataTransfer()
      _this.files = dataTransfer.files

      return false;
    }

    reader.readAsDataURL(event.target.files[0]);
    fileName = event.target.files[0].name
  });

  cropModal && cropModal.addEventListener('shown.bs.modal', function (event) {

    console.log('Event listener', cropWidth, cropHeight, aspectRatio)

    cropper = new Cropper(document.getElementById('avatar-cropper'), {
      dragMode: 'move',
      aspectRatio: aspectRatio,
      viewMode: 1,
      cropBoxResizable: false,
      minCropBoxWidth: cropWidth,
      minCropBoxHeight: cropHeight
    });
  })

  cropModal && cropModal.addEventListener('hidden.bs.modal', function() {
    console.log('Hide the crop modal, destroy the cropper')
    cropper.destroy();
    _this = null;
    cropper = null;
  })

  // this will submit action will update the preview image and set the form data on the settings page
  const cropAvatar = document.getElementById('crop-avatar')
  cropAvatar && cropAvatar.addEventListener('click', async function () {
    modal.hide();

    const canvas = cropper.getCroppedCanvas({
      width: cropWidth,
      height: cropHeight,
    });

    // shameless stolen from
    // https://stackoverflow.com/questions/5632629/how-to-change-a-file-inputs-filelist-programmatically

    // update the preview for this specific cropping action
    let previewSelector = "#cropped-preview";
    let previewPlaceholderSelector = "#cropped-preview-placeholder"
    if (previewId) {
      previewSelector += `-${previewId}`;
      previewPlaceholderSelector += `-${previewId}`;
    }

    $(previewSelector).attr("src", canvas.toDataURL());
    if ($(previewPlaceholderSelector)) {
      $(previewPlaceholderSelector).removeClass("d-block").addClass("d-none");
      $(previewSelector).removeClass("d-none").addClass("d-block");
    }

    // update the form data
    const dataTransfer = new DataTransfer()
    const blob = await new Promise(resolve => canvas.toBlob(resolve));
    const file = new File([blob], `${fileName.split('.')[0]}${previewId ? `-${previewId}` : ''}-cropped.jpg`, { type: 'image/jpeg' })
    dataTransfer.items.add(file)
    //document.querySelector('#cropped-upload').files = dataTransfer.files
    _this.files = dataTransfer.files
  });

  $("#zoom-in").on('click', function() {
    cropper.zoom(0.1)
  });

  $("#zoom-out").on('click', function() {
    cropper.zoom(-0.1)
  });

  $("#move-left").on('click', function() {
    cropper.move(-10, 0)
  });

  $("#move-right").on('click', function() {
    cropper.move(10, 0)
  });

  $("#move-up").on('click', function() {
    cropper.move(0, -10)
  });

  $("#move-down").on('click', function() {
    cropper.move(0, 10)
  });

})
